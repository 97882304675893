import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "../elements/Mui/mui_Autocomplete";
import MuiInput from "../elements/Mui/mui_input";
import MuiDatePicker from "../elements/Mui/mui_date_picker";
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { useState, useEffect, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { useMediaQuery } from "react-responsive";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomAxios from "../utils/CustomAxios";
import MuiInputAmount from "../elements/Mui/mui_input_amount";

export function Deduction (){
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [deduction, setDeduction] = useState("Process Deduction")
  const [ded_narration, setDedNarrations] = useState()
  const [visible, setVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const [minShare, setMinShare] = useState(0);
  const [minAge, setMinAge] = useState(0);
  const [loading, setLoading] = useState(false)
  const [deductiongen, setDeductionGen] = useState([])
  const [maxAge, setMaxAge] = useState(0);
  const [transdate, setTransDate] = useState(null);
  const [narration, setNarration] = useState(null);
  const [begindate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dedfrom, setDedFrom] = useState("Share");
  const [postDeduct, setPostDeduct] = useState(false)
  const [amount, setAmount] = useState(40)
  const [totalmembers, setTotalMembers] = useState(0)
  const [totaldeduction, setTotalDeduction] = useState(0)
  const [error, setError] = useState('')
  let allchequestatus = ["Deduction-All","Withdrawal","Not Deducted","Insufficient Balance"]
  const minShBalanceRef=useRef(null)
  const deductRef=useRef(null)

  useEffect(()=>{
      const gettingStates = async () => {
          const finalMembers = await CustomAxios.get('oasis/form_details/').then((data) => {
              setLoading(false)
              setDedNarrations(data.data.ded_narrations)
          })
      }
      gettingStates()
     
  },[])

  const handleFormattedAmount = amount => parseFloat(amount?.toString().replace(/[^0-9.]/g, ''))

  const exportExcel = () => {
    const rearragedData = deductiongen.map((item,index)=>({
      S_No: index + 1,
      Bank:item.bank,
      M_No:item.memberid,
      Member_Name:item.membername,
      DBS_Deduct:40.00,
      Cheque_Amount:40.00,
      Total_Rounded_Dividend:item.total_rounded_dividend,
      narration:item.narration,
      Share_balance:item.beginingsharebalance,
      Subscription_Balacne:item.beginingsubsbalance,
      transdate:item.transdate,
      State:item.state,
      Estate:item.estate,
      PV_No:item.remarks,
    }))
      import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(rearragedData);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, {
              bookType: 'xlsx',
              type: 'array'
          });
  
          saveAsExcelFile(excelBuffer, 'processed_deduction');
      });
  };
  
  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then((module) => {
          if (module && module.default) {
              let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
              let EXCEL_EXTENSION = '.xlsx';
              const data = new Blob([buffer], {
                  type: EXCEL_TYPE
              });
  
              module.default.saveAs(data, fileName + EXCEL_EXTENSION);
          }
      });
  };

  const decimalbsh =(rowData)=>{
      return parseFloat(rowData.beginingsharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

  const decimalbsu =(rowData)=>{
  return parseFloat(rowData.beginingsubsbalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const [filters, setFilters] = useState({
      memberid:{value:null, matchMode:FilterMatchMode.CONTAINS},
      membername:{value:null, matchMode:FilterMatchMode.CONTAINS},
      state:{value:null, matchMode:FilterMatchMode.CONTAINS},
      estate:{value:null, matchMode:FilterMatchMode.CONTAINS}
  })

  const header = (
      <div className="flex align-items-center justify-content-end gap-2" style={{display:"flex", justifyContent:"flex-end"}}>
          <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>Download Processed Deductions</Typography>
          <Button sx={{display:"flex"}} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
      </div>
  );

  const [deductionColumn, setDeductionColumn] = useState([
      {field:"memberid", header:"M.No", visible:true, style:{width:"100px"}, filter:true},
      {field:"membername", header:"Member Name", visible:true, style:{width:"200px"}, filter: true},
      {field:"nricnew", header:"New Nric", visible:true, style:{width:"200px"}},
      {field:"nricold", header:"Old Nrice", visible:true, style:{width:"200px"}},
      {field:"state", header:"State", visible:true, style:{width:"200px"}, filter: true},
      {field:"estate", header:"Estate", visible:true, style:{width:"200px"}, filter:true},
      {field:"beginingsharebalance", header:"Share Balance", body:decimalbsh, visible:true, style:{width:"200px"}},
      {field:"beginingsubsbalance", header:"Subscription Balance", body:decimalbsu, visible:true, style:{width:"200px"}},
      {field:"age", header:"Age", visible:true, style:{width:"200px"}},
      {field:"remarks", header:"PV No", visible:true, style:{widht:"200px"}}
    ])

  const clearform = () => {
      window.location.reload();
  }
  const handleFetchDeductionDetails = async (e)=>{
      e.preventDefault();
      setLoading(true)
      let formattedbegindate = dateformate(begindate?.format("YYYY-MM-DDTHH:mm:ss[Z]"))
      let formattedenddate = dateformate(endDate?.format("YYYY-MM-DDTHH:mm:ss[Z]"))
      if (deduction=="Process Deduction"){
      const payload={
          begin_date:formattedbegindate,
          end_date:formattedenddate,
          min_age:minAge,
          max_age:maxAge,
          deduct_amount:handleFormattedAmount(amount),
          share_margin:handleFormattedAmount(minShare),
          transtype:dedfrom,
      }
      if (maxAge<minAge){
          setLoading(false)
          setError('Maximum age should not be less than Minimum Age')
      }
      else
      if(transdate==null || begindate==null || endDate==null){
          setLoading(false)
          setError("All fields are required")
      }
      else{
          setError('')
      await CustomAxios.post('oasis/process_deduction/', payload).then((data) => {
          setLoading(false)
          setVisible(false)
          setDeductionGen(data.data.data)
          let members = data.data.data.length
          setTotalMembers(members)
          setTotalDeduction(handleFormattedAmount(amount)*members)
          setPostDeduct(true)
      })}}
      else{
          if (maxAge<minAge){
              setLoading(false)
              setError('Maximum age should not be less than Minimum Age')
          }
          else
          if(transdate==null || selectedOption==null || narration==null){
              setLoading(false)
              setError("All fields are required")
          }
          else{
              setError('')
              await CustomAxios.get(`oasis/process_deduction/?narration=${narration}&type=${selectedOption}&begin_date=${formattedbegindate}&end_date=${formattedenddate}&deduct_amount=${handleFormattedAmount(amount)}`).then((data) => {
              setLoading(false)
              setVisible(false)
              setDeductionGen(data.data.data)
              let members = data.data.data.length
              setTotalMembers(members)
              setTotalDeduction(handleFormattedAmount(amount)*members)
          })}}

      }
  
  const dateformate = (date) =>{
      let formattedDate
          const CDate = new Date(date)
          const year = CDate.getFullYear();
          const month = String(CDate.getMonth() + 1).padStart(2, '0');
          const day = String(CDate.getDate()).padStart(2, '0');
          const hours = String(CDate.getHours()).padStart(2, '0');
          const minutes = String(CDate.getMinutes()).padStart(2, '0');
          const seconds = String(CDate.getSeconds()).padStart(2, '0');
          formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
          console.log(formattedDate)
          return formattedDate
      }
  const handleDeductionWithdrawal = async (e) => {
      e.preventDefault();

      let formattedbegindate = dateformate(begindate?.format("YYYY-MM-DDTHH:mm:ss[Z]"))
      let formattedenddate = dateformate(endDate?.format("YYYY-MM-DDTHH:mm:ss[Z]"))

      const payload={
          begin_date:formattedbegindate,
          end_date:formattedenddate,
          min_age:minAge,
          max_age:maxAge,
          year:new Date(formattedenddate).getFullYear(),
          deduct_amount:handleFormattedAmount(amount),
          transdate:dateformate(transdate?.format("YYYY-MM-DDTHH:mm:ss[Z]")),
          share_margin:handleFormattedAmount(minShare),
          transtype:dedfrom,
          records:deductiongen,
          createdby:localStorage.getItem('username'),
      }
      setLoading(true)
      await CustomAxios.post('oasis/deduction_withdrawl/', payload).then((data) => {
          setLoading(false)
          setVisible(false)
          setDeductionGen(data.data.data)
          let members = data.data.data.length
          setTotalMembers(members)
          setTotalDeduction(handleFormattedAmount(amount)*members)
          setPostDeduct(false)
      })
  }

  const handleDeductionChange = (e, ded) => {
      setError("")
      setDeduction(ded)
      setTransDate(null)
      setSelectedOption(null)
      setMinAge(0)
      setMaxAge(0)
      setMinShare(0)
      setNarration(null)
      setBeginDate(null)
      setEndDate(null)
  }

  return(
      <Box className="fulldiv" style={{ width: "100%", height: "calc(100vh - 64px)", marginTop:"60px"}}>

      {visible==false ? <Box>
      <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
          <Box sx={{width:"100%",top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                       <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                          <Box sx={{width:"32px",height:"32px"}}>
                                  <PriceChangeOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                           </Box>
                   <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>Deduction</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", width:"400px" }}>
              <Button onClick={(e)=>{
                  setVisible(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PriceChangeOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Process Deduction</Typography></Button>
              {postDeduct && <Button onClick={(e)=>{
                  handleDeductionWithdrawal(e)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PriceChangeOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>{loading? <CircularProgress color="inherit" size={30}/>: <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Dividend Withdrawal</Typography>}</Button>}
              <Button onClick={(e)=>{
                  clearform()}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><ClearIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Clear</Typography></Button>
              </Box>
          </Box>

          <Box sx={{display:"flex", flexDirection:"column", gap:"20px", width:"100%"}}>
              <Box sx={{display:"flex", flexDirection:"row", gap:"20px", borderBottom:"1px solid #CBD5E1"}}>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Deduct From</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{dedfrom}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Share Amount</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{minShare}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Min Age</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{minAge}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Max Age</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{maxAge}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Deduct Amount</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{amount}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Total Members</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{totalmembers}</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"400",lineHeight:"28px",color:"#242424"}}>Total Deduction</Typography>
              <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"700",lineHeight:"28px",color:"#242424"}}>:{totaldeduction}</Typography>
              </Box>
              </Box>

              {deductiongen?.length>0 && <Box sx={{width:"100%"}}>
                  <DataTable value={deductiongen} visible={deductiongen?.length>0 && true} header = {header} scrollable style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filters={filters} filterDisplay="row" emptyMessage="No Dividends Generated.">
                  <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                  {deductionColumn.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                  </DataTable>
                  </Box>}

          </Box>
          </Box>
          </Box>:<Box sx={{
                  backgroundColor: isMobile && "#f2f6fc",
                  minHeight: isMobile && "100vh",
                  width: "100%",
              }}>
          <Box sx={{ padding: "20px" }}>
          <Dialog
              visible={visible}
              style={{ width: "1000px", height: "550px", position: "relative" }}
              onHide={(e) => clearform(e)}
          >
          <form onSubmit={(e)=>handleFetchDeductionDetails(e)}>
          <Box sx={{ padding: "0px 20px 20px 20px" }}>
              <Typography sx={{ color: "black", fontWeight: "600" }}>
              Criteria to Process / View Existing Deduction
              </Typography>
          </Box>
          <Box sx={{ display:"flex", flexDirection:"column", gap:"20px", padding:"20px"}}>
          <Box sx={{ marginTop:"20px", display: "flex", gap: "20px", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RadioButton
                  style={{ display: "flex", alignItems: "center" }}
                  inputId="type1"
                  name="dividend"
                  value="Process Deduction"
                  onChange={(e) => handleDeductionChange(e, "Process Deduction")}
                  checked={deduction=="Process Deduction"}
                />
                <label htmlFor="type1" className="ml-2">
                  Process Deduction
                </label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type2"
                    name="dividend"
                    value="View Existing"
                    onChange={(e) => handleDeductionChange(e, "View Existing")}
                    checked={deduction === "View Existing"}
                  />
                  <label htmlFor="type2" className="ml-2">
                    View Existing
                  </label>
                  <Box>
                    {/* <Select
                      displayEmpty
                      style={{
                        width: "150px",
                        height: "36px",
                        fontFamily: "inter",
                        fontSize: "16px",
                      }}
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      disabled={Dividend !== "View Existing" ? true : false}
                    >
                      <MenuItem value="" disabled>
                        Select Option
                      </MenuItem>
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="Not Cleared">Not Cleared</MenuItem>
                      <MenuItem value="Cleared">Cleared</MenuItem>
                      <MenuItem value="Cheque Canceled">
                        Cheque Canceled
                      </MenuItem>
                    </Select> */}
                  </Box>
                </Box>
                
              </Box>
              <Box
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
              <MuiAutocomplete
                              labelId="select-state-label"
                              id="select-state"
                              required
                              value={dedfrom}
                              onChange={(event, newValue) => {
                                setDedFrom(newValue);
                              }}
                              options={["Share", "Subscription"].sort()}
                              label = "Deduct From"
                              autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                              textFieldStyle={{
                                width: "200px",
                                fontSize: "8px",
                                fontWeight: "400",
                                "& .MuiInputBase-input": {
                                  color: "#000",
                                  marginLeft: "10px",
                                },
                                color: "#101010",
                              }}
                            />
                            </Box>

              <Box
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <MuiDatePicker
                    label="Trans Date"
                    required
                    value={transdate}
                    onChange={(e) => setTransDate(e)}
                    disableFuture
                    // minDate={new Date('2000-01-01')}
                    format="DD-MM-YYYY"
                    sx={{ backgroundColor: "white", width:"230px" }}
                  />
              </Box>

            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
            <MuiDatePicker
                    label="Begin Date"
                    required
                    value={begindate}
                    onChange={(e) => setBeginDate(e)}
                    disableFuture
                    // minDate={new Date('2000-01-01')}
                    format="DD-MM-YYYY"
                    sx={{ backgroundColor: "white" }}
                  />
                {/* <Typography
                  sx={{
                    alignSelf: "stretch",
                    width: "200px",
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontStyle: "inter",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#242424",
                  }}
                >
                  End - Date
                </Typography> */}
                <MuiDatePicker
                    label="End Date"
                    required
                    value={endDate}
                    onChange={(e) => setEndDate(e)}
                    disableFuture
                    // minDate={new Date('2000-01-01')}
                    format="DD-MM-YYYY"
                    sx={{ backgroundColor: "white" }}
                  />
              </Box>
            {deduction=="View Existing" && <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
            <MuiAutocomplete
                              labelId="select-state-label"
                              id="select-state"
                              required
                              value={narration}
                              onChange={(event, newValue) => {
                                setNarration(newValue);
                              }}
                              options={ded_narration?.sort()}
                              label = "Pollicy #"
                              autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                              textFieldStyle={{
                                width: "460px",
                                fontSize: "8px",
                                fontWeight: "400",
                                "& .MuiInputBase-input": {
                                  color: "#000",
                                  marginLeft: "10px",
                                },
                                color: "#101010",
                              }}
                            />
            <MuiAutocomplete
                              labelId="select-state-label"
                              id="select-state"
                              required
                              value={selectedOption}
                              onChange={(event, newValue) => {
                                setSelectedOption(newValue);
                              }}
                              options={allchequestatus.sort()}
                              label="Cheque Status"
                              autoCompleteStyle={{ width: "230px", marginTop: "0px" }}
                              textFieldStyle={{
                                width: "460px",
                                fontSize: "8px",
                                fontWeight: "400",
                                "& .MuiInputBase-input": {
                                  color: "#000",
                                  marginLeft: "10px",
                                },
                                color: "#101010",
                              }}
                            /></Box>}
              <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
            {/* <MuiInput
                  type="number"
                  label="Min Share balance"
                  required
                  variant="outlined"
                  value={minShare}
                  onChange={(e) => setMinShare(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              /> */}
              <MuiInputAmount
                    inputKey="amount1_deduction"
                    ref={minShBalanceRef}
                    amount={minShare}
                    setAmount={setMinShare}
                    label="Min Share balance"
                    required
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                />
              <MuiInput
                  type="number"
                  label="Min Age"
                  required
                  variant="outlined"
                  value={minAge}
                  onChange={(e) => setMinAge(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              />
              <MuiInput
                  type="number"
                  label="Max Age"
                  required
                  variant="outlined"
                  value={maxAge}
                  onChange={(e) => setMaxAge(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              />

            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
            {/* <MuiInput
                  type="number"
                  label="Deduct Amount"
                  required
                  variant="outlined"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              /> */}
              <MuiInputAmount
                    inputKey="amount2_deduction"
                    ref={deductRef}
                    amount={amount}
                    setAmount={setAmount}
                    label="Deduct Amount"
                    required
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      fontSize: "14px",
                      color:"#101010",
                    }}
                />
              <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>X</Typography>
              <MuiInput
                  type="number"
                  label="Total Members"
                  disabled
                  variant="outlined"
                  value={totalmembers}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              />
              <Typography sx={{fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"#242424"}}>=</Typography>
              <MuiInput
                  label="Total Deductions"
                  disabled
                  variant="outlined"
                  value={"RM " + totaldeduction?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    fontSize: "14px",
                    color:"#101010",
                  }}
              />
            </Box>
            {error!="" && <Typography sx={{marginLeft:"20px",fontFamily:"inter",fontSize:"14px",fontWeight:"600",lineHeight:"28px",color:"red"}}>{error}</Typography>}
            {deduction=="Process Deduction" && <Button type="submit" fullWidth variant="contained" color="primary" sx={{width:"165px",height:"48px",borderRadius:"4px",display:"flex"}}>{loading? <CircularProgress color="inherit" size={30}/> : <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>Process</Typography>}</Button>}
          {deduction=="View Existing" && <Button type="submit" fullWidth variant="contained" color="primary" sx={{width:"165px",height:"48px",borderRadius:"4px",display:"flex"}}>{loading? <CircularProgress color="inherit" size={30}/> : <Typography sx={{width:"100%",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",textTransform:"none",textAlign:"center"}}>View Existing</Typography>}</Button>}
            </Box>
          </form>
          </Dialog>
          </Box>
          </Box>}
      </Box>
  )

}